import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Input, Skeleton } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';
import querySearch from 'stringquery';

import { getOrderCod, getShipmentTrackingData } from 'services/shipments';
import { mediaHook } from 'customHooks';
import {
  checkDate,
  dates,
  DATE_FORMAT,
  DATE_FORMAT_WITH_DAY,
  DATE_FORMAT_WITHOUT_YEAR,
  DATE_FORMAT_WITH_DAY_WITHOUT_YEAR,
  ARABIC_LANG
} from 'utils/helpers';
import { openModal } from 'utils/modal';
import { sendSegment } from 'utils/segment';
import {
  EXCEPTIONS_CODES,
  FLASH_DOWNLOAD_URL,
  LOCAL_STORAGE_STAR_API_KEY,
  ORDER_TYPES
} from 'constants/shipments';
import { COUNTRIES_ENGLISH_LOCALE, LOCALE } from 'constants/intl-wrapper';
import { DELIVERY_STATES_CODES } from 'constants/states';
import {
  generateInternationalOrdersLogs,
  getAdjustedInternationalState,
  getArrivalDate,
  getInternationalOrderLastUpdatedAt,
  getLastConsigneeLog,
  isCanceledOrder
} from 'utils/tracking';
import {
  isValUAvailable,
  setCurrentUserCountryData
} from 'constants/countries/countries-mapping';
import { initializeFreshChat } from 'utils/freshchat';
import { useAuth } from 'context/AuthContext';

import BRButton from 'components/BRButton/BRButton';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import TrackingLogs from './components/TrackingLogs/TrackingLogs';
import DateAddressEdit from './components/DateAddressEdit/DateAddressEdit';
import ExceptionCard from './components/ExceptionCard/ExceptionCard';
import PhoneNumberModal from './components/PhoneNumberModal/PhoneNumberModal';
import TrackingTimeline from './components/TrackingTimeline/TrackingTimeline';
import StarInfo from './components/StarInfo/StarInfo';
import RateDeliveryServiceModal from 'components/RateDeliveryServiceModal/RateDeliveryServiceModal';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg';
import { ReactComponent as UpArrow } from 'assets/icons/Chevron-up.svg';
import { ReactComponent as Alert } from 'assets/icons/Alert.svg';
import TrackingIcon from 'assets/images/tracking-icon.png';
import ValuImg from 'assets/images/valu.png';
import ValuAstric from 'assets/images/valuastric.png';
import flashBannerLg from 'assets/images/flashBanner-lg.png';
import flashBannerSm from 'assets/images/flashBanner-sm.png';
import flashBannerLgAr from 'assets/images/flashBanner-lg-ar.png';
import flashBannerSmAr from 'assets/images/flashBanner-sm-ar.png';

import './ShipmentTracking.less';

const ShipmentTracking = ({ intl, location, history, mobileScreenSizes }) => {
  const { isSmallMobileScreen } = mobileScreenSizes;
  const { apiKey, setAPIKey } = useAuth();

  const { Search } = Input;

  const [trackingNumber, setTrackingNumber] = useState(() => {
    if (!location.search) {
      return null;
    }
    const urlSearchParams = new URLSearchParams(location.search);
    return (
      urlSearchParams.get('shipment-number') || urlSearchParams.get('track_num')
    );
  });
  const [trackingDetails, setTrackingDetails] = useState({
    handleWhenRefreshShowNothing: true
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const [exceptionData, setExceptionData] = useState(null);
  const [showDeliveryLogs, setShowDeliveryLogs] = useState(false);
  const [codAmount, setCodAmount] = useState(0);

  const query = querySearch(window.location.search);

  useEffect(() => {
    if (apiKey) {
      fetchOrderCod();
    }
  }, [apiKey]);

  useEffect(() => {
    getTrackingData();
  }, [trackingNumber]);

  const onSearch = (value) => {
    setTrackingNumber(value);
    history.push(`/tracking-shipments?shipment-number=${value?.trim()}`);
  };

  const groupBy = (array, key) => {
    return array.reduce((result, currentItem) => {
      let day = checkDate(currentItem[key]);
      day = `${day ? `${day},` : ''} ${dates(
        currentItem[key],
        day ? DATE_FORMAT_WITHOUT_YEAR : DATE_FORMAT_WITH_DAY_WITHOUT_YEAR
      )}`;
      (result[day] = result[day] || []).push(currentItem);
      return result;
    }, []);
  };

  const fetchOrderCod = async () => {
    try {
      const { data } = await getOrderCod({ token: apiKey });
      setCodAmount(data);
    } catch (error) {
      notify({ msg: error.message });
      setAPIKey(null);
    }
  };

  const getTrackingData = async () => {
    try {
      setIsLoading(true);
      setExceptionData(null);
      const data = await getShipmentTrackingData({
        trackingNumber: trackingNumber.trim(),
        lang: COUNTRIES_ENGLISH_LOCALE.includes(intl.locale)
          ? LOCALE.EN
          : ARABIC_LANG
      });

      if (query?.addRating) {
        openModal(RateDeliveryServiceModal, { trackingNumber });
      }

      if (data?.deliveryCountryCode) {
        setCurrentUserCountryData({
          codeName: data?.deliveryCountryCode
        });
      }

      if (data?.exceptionCode) {
        setExceptionData({
          code: data.exceptionCode,
          date: data?.TransitEvents?.findLast((evt) => !!evt?.exceptionCode)
            ?.timestamp,
          ...EXCEPTIONS_CODES[data.exceptionCode]
        });
      }

      if (data?.isInternationalOrder) {
        data.Type = ORDER_TYPES.INTERNATIONAL;
        data.TransitEvents = generateInternationalOrdersLogs(data);
        data.CurrentStatus = getAdjustedInternationalState(data);
      }

      if (data?.TransitEvents) {
        const lastLog = getLastConsigneeLog(data);
        const lastLogDay = checkDate(lastLog?.timestamp);
        data['lastLog'] = lastLog?.state;
        data['msg'] = lastLog?.msg;
        data['lastLogDate'] = `${lastLogDay ? `${lastLogDay},` : ''} ${dates(
          lastLog?.timestamp,
          lastLogDay ? DATE_FORMAT : DATE_FORMAT_WITH_DAY
        )}`;

        data['GroupedTransitEvents'] = groupBy(
          data?.TransitEvents,
          'timestamp'
        );
      }

      if (!data?.TransitEvents) {
        data.TransitEvents = [];
      }

      const difference =
        +new Date() -
        +new Date(
          data?.isInternationalOrder
            ? getInternationalOrderLastUpdatedAt(data)
            : data?.CurrentStatus?.timestamp
        );
      const time = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };

      if (
        data?.TrackingNumber &&
        (isCanceledOrder({ trackingDetails: data }) ||
          data?.exceptionCode ||
          data?.CurrentStatus?.code === DELIVERY_STATES_CODES.EXCEPTION)
      ) {
        !window?.fcWidget?.isInitialized()
          ? initializeFreshChat()
          : window?.fcWidget?.show();
      } else {
        window?.fcWidget?.hide();
      }

      setTrackingDetails({
        ...data,
        lastUpdateDate: { ...time }
      });
    } catch (error) {
      setTrackingDetails({});
      setAPIKey(null);
    }
    setIsLoading(false);
  };

  const getLastUpdateDate = () => {
    return (
      <>
        {!!trackingDetails?.lastUpdateDate?.days &&
          intl.formatMessage(
            {
              id: `tracking.${
                trackingDetails?.lastUpdateDate?.days > 10 ||
                (trackingDetails?.lastUpdateDate?.days === 1 &&
                  intl.locale === LOCALE.AR)
                  ? 'day'
                  : 'days'
              }`
            },
            { days: trackingDetails?.lastUpdateDate?.days }
          )}
        {!!trackingDetails?.lastUpdateDate?.hours &&
          !trackingDetails?.lastUpdateDate?.days &&
          intl.formatMessage(
            {
              id: `tracking.${
                trackingDetails?.lastUpdateDate?.hours > 10 ||
                trackingDetails?.lastUpdateDate?.hours === 1
                  ? 'hour'
                  : 'hours'
              }`
            },
            { hours: trackingDetails?.lastUpdateDate?.hours }
          )}
        {!trackingDetails?.lastUpdateDate?.days &&
          !trackingDetails?.lastUpdateDate?.hours &&
          !!trackingDetails?.lastUpdateDate?.minutes &&
          intl.formatMessage(
            {
              id: `tracking.minutes`
            },
            { minutes: trackingDetails?.lastUpdateDate?.minutes }
          )}
        {!trackingDetails?.lastUpdateDate?.days &&
          !trackingDetails?.lastUpdateDate?.hours &&
          !trackingDetails?.lastUpdateDate?.minutes &&
          !!trackingDetails?.lastUpdateDate?.seconds &&
          intl.formatMessage(
            {
              id: `tracking.seconds`
            },
            { seconds: trackingDetails?.lastUpdateDate?.seconds }
          )}
        {intl.formatMessage({ id: 'tracking.ago' })}
      </>
    );
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const verifyIdentity = (props) => {
    const {
      afterVerification = (token) => {
        setAPIKey(token);
      }
    } = props || {};

    sendSegment('Consignee_confrims_Idenitiy', {
      Source: 'Consignee_tracking_page'
    });

    openModal(PhoneNumberModal, {
      trackingNumber,
      setAPIKey,
      afterVerification,
      deliveryCountryCode: trackingDetails?.deliveryCountryCode
    });
  };

  const shouldRenderLastLogDate = () => {
    return (
      trackingDetails?.CurrentStatus?.code !== DELIVERY_STATES_CODES.CREATED &&
      trackingDetails?.Type !== ORDER_TYPES.RTO
    );
  };

  const renderValuBanner = () => {
    return (
      isValUAvailable() && (
        <div className="br-shipment-tracking__valu-banner-container">
          <div className="br-shipment-tracking__valu-banner">
            <div>
              <img src={ValuImg} alt="valu-logo" />
              <p className="body">
                {intl.formatMessage({
                  id: 'tracking.valu_banner'
                })}
              </p>
            </div>
            <img src={ValuAstric} alt="valu-astric-icon" />
          </div>
        </div>
      )
    );
  };

  const renderFlashBanner = () => {
    const lang = intl.locale.split('-')[0];
    const imgSrc = isSmallMobileScreen
      ? lang === LOCALE.EN
        ? flashBannerSm
        : flashBannerSmAr
      : lang === LOCALE.EN
      ? flashBannerLg
      : flashBannerLgAr;

    return (
      <a
        className="br-shipment-tracking__flash-banner-container"
        href={FLASH_DOWNLOAD_URL}
        target="_blank"
      >
        <img src={imgSrc} alt="flash-banner" />
      </a>
    );
  };

  return (
    <div
      className={classnames('br-shipment-tracking  br-hero-main', {
        'br-shipment-tracking__empty-state': !(
          Object.keys(trackingDetails).length > 1
        )
      })}
    >
      <div className="br-tracking__header">
        <img src={TrackingIcon} alt="tracking-icon" />
        <div className="display-xl">
          {intl.formatMessage({
            id: 'tracking.title'
          })}
        </div>
        <div className="br-tracking__header-subtitle">
          {intl.formatMessage({
            id: 'tracking.sub_title'
          })}
        </div>
      </div>
      <LoadingWrapper loading={isLoading}>
        <div className="br-shipment-tracking__empty-data">
          {!isSmallMobileScreen && (
            <div className="br-shipment-tracking-track-input-container">
              <div className="br-shipment-tracking__tracking-number-field">
                <Search
                  placeholder={intl.formatMessage({
                    id: 'tracking.input_placeholder'
                  })}
                  onSearch={onSearch}
                  enterButton={<SearchIcon />}
                />
              </div>
            </div>
          )}

          {!!trackingNumber &&
            !Object.keys(trackingDetails).length &&
            !trackingDetails.handleWhenRefreshShowNothing && (
              <>
                <div className="br-shipment-tracking__details__tracking-number heading">
                  {intl.formatMessage(
                    {
                      id: 'common.order_no'
                    },
                    {
                      trackingNumber
                    }
                  )}
                </div>
                <div className="br-shipment-tracking__empty-data__alert">
                  <span>
                    <Alert />
                  </span>
                  {intl.formatMessage({ id: 'tracking.empty_data_alert' })}
                </div>
              </>
            )}
        </div>

        {/* {renderValuBanner()} */}
        {renderFlashBanner()}

        {Object.keys(trackingDetails).length > 1 && (
          <div className="br-shipment-details-section">
            <div className="br-shipment-tracking__details">
              <div className="br-shipment-tracking-details__header">
                {!trackingDetails.handleWhenRefreshShowNothing && (
                  <div className="br-shipment-tracking__details__tracking-number subheading">
                    {intl.formatMessage(
                      {
                        id: 'common.order_no'
                      },
                      {
                        trackingNumber: trackingNumber
                      }
                    )}
                  </div>
                )}
                {!!Object.keys(trackingDetails).length &&
                  !trackingDetails.handleWhenRefreshShowNothing && (
                    <Skeleton
                      loading={isLoading}
                      paragraph={{ rows: 3 }}
                      active
                    >
                      <span className="display-md">
                        {getArrivalDate({ trackingDetails })}
                      </span>
                      {!trackingDetails?.isDraftOrder && (
                        <>
                          {isSmallMobileScreen && (
                            <div className="br-shipment-tracking__details--header__subtitle">
                              {trackingDetails?.lastLog}
                              {shouldRenderLastLogDate() && (
                                <span>{trackingDetails?.lastLogDate}</span>
                              )}
                            </div>
                          )}
                          {!isSmallMobileScreen && (
                            <>
                              <div className="br-shipment-tracking__details--header__subtitle">
                                {trackingDetails?.lastLog}
                                {shouldRenderLastLogDate() && (
                                  <span>{trackingDetails?.lastLogDate}</span>
                                )}
                              </div>
                              <div className="br-shipment-tracking__details--update-date">
                                <div>{trackingDetails?.msg}</div>(
                                {intl.formatMessage({
                                  id: 'tracking.last_update'
                                })}
                                {intl.formatMessage({ id: 'tracking.since' })}
                                {getLastUpdateDate()})
                              </div>
                            </>
                          )}
                          {isSmallMobileScreen && (
                            <div className="br-shipment-tracking__details--mobile-details">
                              <div className="br-shipment-tracking__details--mobile-details-date">
                                <span>
                                  {intl.formatMessage({
                                    id: 'tracking.last_update'
                                  })}
                                </span>
                                <span>
                                  {intl.formatMessage({ id: 'tracking.since' })}
                                  {getLastUpdateDate()}
                                </span>
                              </div>
                            </div>
                          )}
                          {!isCanceledOrder({ trackingDetails }) &&
                            ![
                              DELIVERY_STATES_CODES.DELIVERED,
                              DELIVERY_STATES_CODES.RETURNED_TO_BUSINESS
                            ].includes(
                              trackingDetails?.CurrentStatus?.code
                            ) && (
                              <StarInfo
                                trackingDetails={trackingDetails}
                                apiKey={apiKey}
                                verifyIdentity={verifyIdentity}
                              />
                            )}
                        </>
                      )}
                      {isCanceledOrder({ trackingDetails }) && (
                        <div className="br-shipment-tracking__details-contact-business body-medium">
                          {intl.formatMessage({
                            id: 'tracking.contact_business'
                          })}
                        </div>
                      )}
                    </Skeleton>
                  )}
              </div>
              {!isCanceledOrder({ trackingDetails }) && (
                <div className="br-shipment-tracking-details__timeline">
                  <TrackingTimeline trackingDetails={trackingDetails} />
                </div>
              )}
            </div>
            {trackingDetails.exceptionCode && (
              <ExceptionCard
                exceptionData={exceptionData}
                apiKey={apiKey}
                verifyIdentity={verifyIdentity}
              />
            )}
            {trackingDetails.isEditableShipment && (
              <DateAddressEdit
                trackingDetails={trackingDetails}
                getTrackingData={() => getTrackingData()}
                apiKey={apiKey}
                verifyIdentity={verifyIdentity}
                codAmount={codAmount}
              />
            )}
            {!showDeliveryLogs && (
              <div className="br-shipment-tracking__details-show-more">
                <BRButton
                  className="button-lg"
                  type="link-color"
                  label={intl.formatMessage({
                    id: 'tracking.timeline.show_more'
                  })}
                  onClick={() => setShowDeliveryLogs(true)}
                />
              </div>
            )}
            {!!Object.keys(trackingDetails).length &&
              !trackingDetails.isDraftOrder &&
              !trackingDetails.handleWhenRefreshShowNothing &&
              showDeliveryLogs && (
                <>
                  <div className="br-shipment-tracking__details--timeline">
                    <div className="br-shipment-tracking__details--timeline__title">
                      {intl.formatMessage({ id: 'tracking.timeline.title' })}
                    </div>
                    <div
                      className={classnames(
                        'br-shipment-tracking__details--logs',
                        {
                          'br-shipment-tracking__details--logs__expaned':
                            isExpanded,
                          'br-shipment-tracking__details--logs__overflowed':
                            overflow && !isExpanded
                        }
                      )}
                      ref={(v) => {
                        v &&
                          !isExpanded &&
                          setOverflow(v.offsetHeight < v.scrollHeight);
                      }}
                    >
                      {!!trackingDetails?.GroupedTransitEvents && (
                        <TrackingLogs trackingDetails={trackingDetails} />
                      )}
                    </div>
                  </div>
                  {overflow && (
                    <BRButton
                      type="link-color"
                      className="button-lg br-shipment-details__expand-collapse"
                      label={intl.formatMessage({
                        id: `tracking.timeline.${
                          isExpanded ? 'collapse' : 'expand'
                        }`
                      })}
                      suffixIcon={
                        <Icon
                          component={UpArrow}
                          className={classnames('ant-icon-md', {
                            'br-shipment-details__expand-collapse__down-arrow':
                              !isExpanded
                          })}
                        />
                      }
                      onClick={handleExpandClick}
                    />
                  )}
                </>
              )}
          </div>
        )}
        <div className="br-shipment-tracking__footer" />
      </LoadingWrapper>
    </div>
  );
};

export default injectIntl(withRouter(mediaHook(ShipmentTracking)));
